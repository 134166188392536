import { isPossiblePhoneNumber, isValidPhoneNumber, validatePhoneNumberLength } from "libphonenumber-js";

export default {
  required: (value) => !!value || "This is Required.",

    
    email: (v) => {
        /* eslint-disable-next-line no-useless-escape*/
        const re =/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
        if (re.test(String(v).toLowerCase().trim())) {
          return true;
        }
        return "Invalid Email";
      },
      phone: (v) => {
        const re =/^(\+91)?[0]?(91)?[6789]\d{9}$/;
        if (re.test(v)) {
          return true;
        }
        return "Invalid Phone Number";
      },
      phone_optional: (v) => {
        const re =/^(\+91)?[0]?(91)?[6789]\d{9}$/;
        if (v === null || v === "") {
          return true;
        } else if (re.test(v)) {
          return true;
        }
        return "Invalid Phone Number";
      },
      no_of_sets:(v)=>{
        if(v ==0){
          return 'No of sets should not be zero'
        }
        return true
      },
      checkZero:(v)=>{
        if(v==0){
          return true
        }
      },
      phone_number_validator: (number, country_code) => {
        if (validatePhoneNumberLength(number, country_code) === 'TOO_SHORT') return 'Mobile number is too short'
        if (validatePhoneNumberLength(number, country_code) === 'TOO_LONG') return 'Mobile number is too long'
        if (isPossiblePhoneNumber(number, country_code) && isValidPhoneNumber(number, country_code)) return true
        return 'Mobile number is not valid'
    },
}