<template>
  <component :is="overlay ? 'v-overlay' : 'div'">
    <div class="d-flex flex-column justify-center align-center">
      <v-progress-circular
        :size="size"
        :width="width"
        :color="color"
        indeterminate
      ></v-progress-circular>
      <div class="mt-5" v-if="loadingText">{{ loadingText }}</div>
    </div>
  </component>
</template>
<script>
import { VOverlay } from 'vuetify/lib'

export default {
  components: { VOverlay },
  props: {
    loadingText: {
      type: String,
      default: "Please wait... Loading",
    },
    overlay: {
      type: Boolean,
      default: true,
    },
    size: {
      type: Number,
      default: 100,
    },
    width: {
      type: Number,
      default: 6,
    },
    color: {
      type: String,
      default: "primary",
    },
  },
};
</script>