<template>
  <v-snackbar
    v-model="snackbar.visible"
    :timeout="3000"
    :color="snackbar.color"
    transition="slide-y-reverse-transition"
    right
  >
    {{ snackbar.text }}
    <template v-slot:action="{ attrs }">
      <v-btn dark icon @click="snackbar.visible = false" v-bind="attrs">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({ snackbar: "app/getSnackbar" }),
  },
};
</script>