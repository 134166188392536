<template>
  <v-navigation-drawer
    v-model="show"
    @keydown.esc="closeDrawer"
    right
    temporary
    hide-overlay
    app
    floating
    width="340"
  >
    <v-toolbar elevation="0" height="60px">
      <v-toolbar-title>Preferences</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeDrawer">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider />
    <v-container>
      <h4 class="mb-2">Preferred Mode</h4>
      <div class="d-flex flex-row" style="gap: 10px">
        <v-btn
          class="rounded-lg"
          depressed
          :color="themeMode === mode.key ? 'primary' : ''"
          v-for="mode in themeModes"
          :key="mode.key"
          @click="changeThemeMode(mode.key)"
        >
          <span class="text-capitalize">{{ mode.title }}</span>
          <v-icon right>{{ mode.icon }}</v-icon>
        </v-btn>
      </div>
    </v-container>
    <v-container>
      <h4 class="mb-2">Preferred Theme</h4>
      <div class="d-flex flex-wrap justify-space-between" style="gap: 15px">
        <v-btn
          class="rounded-lg"
          depressed
          v-for="theme in themes"
          :key="theme.key"
          :color="theme[$vuetify.theme.dark ? 'dark' : 'light'].primary"
          @click="changeTheme(theme.key)"
          height="50"
          width="50"
        >
          <v-icon v-if="theme.key === themeColor" color="white" size="30"
            >mdi-check</v-icon
          >
        </v-btn>
      </div>
    </v-container>
    <v-divider />
    <v-container>
      <div class="d-flex align-start" style="gap: 15px">
        <v-icon>mdi-bell-ring</v-icon>
        <div>
          <h4 class="mb-0">Push Notifications</h4>
          <div class="text-body-2">
            Choose to enable or disable push notifications
          </div>
          <a
            class="mt-2 text-caption"
            href="https://4-loop.notion.site/Web-Push-Notification-Troubleshooting-7abdba4d0ebd470cb2e72336ea28606a?pvs=4"
            target="_blank"
          >
            Need Help!, not getting notification
          </a>
        </div>
        <v-switch
          v-model="notificationEnabled"
          :loading="notificationSwitchLoading"
          :disabled="notificationSwitchLoading"
        />
      </div>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import themes from "@/common/themes";

export default {
  name: "SettingsDrawer",
  data() {
    return {
      themeModes: [
        { key: "light", title: "Light", icon: "mdi-white-balance-sunny" },
        { key: "dark", title: "Dark", icon: "mdi-weather-night" },
        { key: "system", title: "System", icon: "mdi-desktop-tower-monitor" },
      ],
      themes: themes,
      notificationEnabled: false,
      notificationSwitchLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      themeMode: "getThemeMode",
      themeColor: "getTheme",
      settingsDrawer: "drawers/settingsDrawer",
    }),

    show: {
      get() {
        return this.settingsDrawer.show;
      },

      set(value) {
        this.toggleSettingsDrawer({ show: value });
      },
    },
  },

  watch: {
    show(value) {
      if (value) {
        this.notificationEnabled = this.user && this.user.notification_enabled;
      }
    },

    notificationEnabled() {
      this.setNotificationEnabled();
    },
  },

  methods: {
    ...mapActions({
      setUser: "setUser",
      setTheme: "setTheme",
      setThemeMode: "setThemeMode",
      toggleSettingsDrawer: "drawers/toggleSettingsDrawer",
    }),

    async setNotificationEnabled() {
      this.notificationSwitchLoading = true;

      if (this.notificationEnabled && Notification.permission !== "granted") {
        const permission = await Notification.requestPermission();
        if (permission == "granted") {
          await this.updateNotificationEnabled();
        }
      } else {
        await this.updateNotificationEnabled();
      }

      this.notificationSwitchLoading = false;
    },

    updateNotificationEnabled() {
      return this.$request(this.$keys.PATCH, this.$urls.auth.profile, {
        data: {
          notification_enabled: this.notificationEnabled,
        },
        onSuccess: (res) => {
          this.setUser(res.data.data);
        },
      });
    },

    closeDrawer() {
      this.toggleSettingsDrawer({ show: false });
    },

    changeThemeMode(mode) {
      this.setThemeMode(mode);
      localStorage.setItem("theme_mode", mode);
    },

    changeTheme(theme_key) {
      this.setTheme(theme_key);
    },
  },
};
</script>
