export default {
  namespaced: true,
  state: {
    activeTab: {
      key: "lead",
      index: 0,
    },

    search: "",

    filters: {
      date: [],
      source: null,
      reason: null,
      executive: null,
    },

    addLeadsModal: {
      show: false,
      id: null,
      type: "",
    },

    followUpModal: {
      show: false,
      id: null,
    },

    irrelevantModal: {
      show: false,
      id: null,
    },

    bottomSheet: {
      show: false,
      selectedClients: [],
    },
  },
  getters: {
    activeTab: (state) => state.activeTab,
    search: (state) => state.search,
    filters: (state) => state.filters,
    getAddLeadsModal: (state) => state.addLeadsModal.show,
    followUpModal: (state) => state.followUpModal,
    irrelevantModal: (state) => state.irrelevantModal,
    bottomSheet: (state) => state.bottomSheet,
  },
  mutations: {
    SET_ACTIVE_TAB(state, tab) {
      const { key, index } = tab;
      state.activeTab.key = key;
      state.activeTab.index = index;
    },

    SET_SEARCH(state, query) {
      state.search = query;
    },

    SET_FILTERS(state, filters) {
      const { date, source, reason, executive } = filters;

      if ("date" in filters) {
        state.filters.date = date;
      }

      if ("source" in filters) {
        state.filters.source = source;
      }

      if ("reason" in filters) {
        state.filters.reason = reason;
      }

      if ("executive" in filters) {
        state.filters.executive = executive;
      }
    },

    TOGGLE_LEADS_MODAL(state, obj) {
      if (obj.show) {
        state.addLeadsModal.id = obj.id ? obj.id : null;
        state.addLeadsModal.type = obj.type ? obj.type : "";
      }
      state.addLeadsModal.show = obj.show;
    },

    TOGGLE_IRRELEVANT_MODAL(state, obj) {
      state.irrelevantModal.show = obj.show;
      state.irrelevantModal.id = obj.id;
    },

    TOGGLE_FOLLOW_UP_MODAL(state, obj) {
      state.followUpModal.show = obj.show;
      state.followUpModal.id = obj.id;
    },

    TOGGLE_BOTTOM_SHEET(state, obj) {
      const { show, selectedClients } = obj;

      if (show) {
        state.bottomSheet.selectedClients = selectedClients;
      } else {
        state.bottomSheet.selectedClients = [];
      }
      
      state.bottomSheet.show = obj.selectedClients;
    },
  },
  actions: {
    setActiveTab({ commit }, obj) {
      commit("SET_ACTIVE_TAB", obj);
    },

    setSearch({ commit }, obj) {
      commit("SET_SEARCH", obj);
    },

    setFilters({ commit }, obj) {
      commit("SET_FILTERS", obj);
    },

    LeadsModal({ commit }, obj) {
      commit("TOGGLE_LEADS_MODAL", obj);
    },

    toggleFollowUpModal({ commit }, obj) {
      commit("TOGGLE_FOLLOW_UP_MODAL", obj);
    },

    toggleIrrelevantModal({ commit }, obj) {
      commit("TOGGLE_IRRELEVANT_MODAL", obj);
    },

    toggleBottomSheet({ commit }, obj) {
      commit("TOGGLE_BOTTOM_SHEET", obj);
    },
  },
};
