export default {
  namespaced: true,
  state: {
    activeTab: {
      key: "pending",
      index: 0,
    },

    search: "",

    filters: {
      date: [],
    },

    addUpdateDialog: {
      show: false,
      id: null,
      caseId: null,
      type: "add",
    },

    approveDialog: {
      show: false,
      id: null,
    },

    rejectDialog: {
      show: false,
      id: null,
    },
  },

  getters: {
    activeTab: (state) => state.activeTab,
    search: (state) => state.search,
    filters: (state) => state.filters,
    addUpdateDialog: (state) => state.addUpdateDialog,
    approveDialog: (state) => state.approveDialog,
    rejectDialog: (state) => state.rejectDialog,
  },

  mutations: {
    SET_ACTIVE_TAB(state, tab) {
      const { key, index } = tab;
      state.activeTab.key = key;
      state.activeTab.index = index;
    },

    SET_SEARCH(state, query) {
      state.search = query;
    },

    SET_FILTERS(state, filters) {
      const { date } = filters;

      if ("date" in filters) {
        state.filters.date = date;
      }
    },

    TOGGLE_ADD_UPDATE_DIALOG(state, obj) {
      const { show, id, caseId, type } = obj;

      if (show) {
        state.addUpdateDialog.id = id;
        state.addUpdateDialog.caseId = caseId;
        state.addUpdateDialog.type = type;
      } else {
        state.addUpdateDialog.id = null;
        state.addUpdateDialog.caseId = null;
        state.addUpdateDialog.type = "add";
      }

      state.addUpdateDialog.show = show;
    },

    TOGGLE_APPROVE_DIALOG(state, obj) {
      const { show, id } = obj;

      if (show) {
        state.approveDialog.id = id;
      } else {
        state.approveDialog.id = null;
      }

      state.approveDialog.show = show;
    },

    TOGGLE_REJECT_DIALOG(state, obj) {
      const { show, id } = obj;

      if (show) {
        state.rejectDialog.id = id;
      } else {
        state.rejectDialog.id = null;
      }

      state.rejectDialog.show = show;
    },
  },
  actions: {
    setActiveTab({ commit }, obj) {
      commit("SET_ACTIVE_TAB", obj);
    },

    setSearch({ commit }, obj) {
      commit("SET_SEARCH", obj);
    },

    setFilters({ commit }, obj) {
      commit("SET_FILTERS", obj);
    },

    toggleAddUpdateDialog({ commit }, obj) {
      commit("TOGGLE_ADD_UPDATE_DIALOG", obj);
    },

    toggleApproveDialog({ commit }, obj) {
      commit("TOGGLE_APPROVE_DIALOG", obj);
    },

    toggleRejectDialog({ commit }, obj) {
      commit("TOGGLE_REJECT_DIALOG", obj);
    },
  },
};
