export default {
  namespaced: true,
  state: {
    addTeam: {
      type: "",
      id: "",
      show: false,
    },

    addEca: {
      type: "",
      id: "",
      show: false,
    },

    addUniversity: {
      type: "",
      id: "",
      show: false,
    },

    addUpdateAgent: {
      show: false,
      id: null,
      type: "add",
    },

    addUpdateService: {
      show: false,
      id: null,
      type: "add",
    },

    addUpdateDocument: {
      show: false,
      id: null,
      type: "add",
    },
  },
  getters: {
    getAddTeam: (state) => state.addTeam,
    getAddEca: (state) => state.addEca,
    getAddUniversity: (state) => state.addUniversity,
    addUpdateAgent: (state) => state.addUpdateAgent,
    addUpdateService: (state) => state.addUpdateService,
    addUpdateDocument: (state) => state.addUpdateDocument,
  },
  mutations: {
    ADD_TEAM(state, obj) {
      if (obj.show) {
        state.addTeam.id = obj.id ? obj.id : null;
        state.addTeam.type = obj.type ? obj.type : "";
      }
      state.addTeam.show = obj.show;
    },
    ADD_ECA(state, obj) {
      if (obj.show) {
        state.addEca.id = obj.id ? obj.id : null;
        state.addEca.type = obj.type ? obj.type : "";
      }
      state.addEca.show = obj.show;
    },
    ADD_UNIVERSITY(state, obj) {
      if (obj.show) {
        state.addUniversity.id = obj.id ? obj.id : null;
        state.addUniversity.type = obj.type ? obj.type : "";
      }
      state.addUniversity.show = obj.show;
    },

    TOGGLE_ADD_UPDATE_AGENT(state, obj) {
      const { show, id, type } = obj;
      if (show) {
        state.addUpdateAgent.id = id;
        state.addUpdateAgent.type = type;
      } else {
        state.addUpdateAgent.id = null;
        state.addUpdateAgent.type = null;
      }
      state.addUpdateAgent.show = show;
    },

    TOGGLE_ADD_UPDATE_SERVICE(state, obj) {
      const { show, id, type } = obj;
      if (show) {
        state.addUpdateService.id = id;
        state.addUpdateService.type = type;
      } else {
        state.addUpdateService.id = null;
        state.addUpdateService.type = null;
      }
      state.addUpdateService.show = show;
    },

    TOGGLE_ADD_UPDATE_DOCUMENT(state, obj) {
      const { show, id, type } = obj;
      if (show) {
        state.addUpdateDocument.id = id;
        state.addUpdateDocument.type = type;
      } else {
        state.addUpdateDocument.id = null;
        state.addUpdateDocument.type = null;
      }
      state.addUpdateDocument.show = show;
    },
  },
  actions: {
    addTeam({ commit }, obj) {
      commit("ADD_TEAM", obj);
    },
    addEca({ commit }, obj) {
      commit("ADD_ECA", obj);
    },
    addUniversity({ commit }, obj) {
      commit("ADD_UNIVERSITY", obj);
    },

    toggleAddUpdateAgent({ commit }, obj) {
      commit("TOGGLE_ADD_UPDATE_AGENT", obj);
    },

    toggleAddUpdateService({ commit }, obj) {
      commit("TOGGLE_ADD_UPDATE_SERVICE", obj);
    },

    toggleAddUpdateDocument({ commit }, obj) {
      commit("TOGGLE_ADD_UPDATE_DOCUMENT", obj);
    },
  },
};
