import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from "libphonenumber-js";

/* eslint-disable no-useless-escape */
export default {
  required: (v) => {
    if (parseInt(v) >= 0) return true;
    return !!v || "This is required";
  },

  email: (value) =>
    value ? /.+@.+/.test(value) || "E-mail must be valid." : true,

  mobile: (value) =>
    value ? /[1-9]{1}[0-9]{9}/.test(value) || "Mobile must be valid." : true,

  password: (value) => {
    if (value.length < 8) return "Password must be at least 8 characters";

    if (value.search(/[a-z]/i) < 0)
      return "Password must contain at least one letter.";

    if (value.search(/[0-9]/) < 0)
      return "Password must contain at least one digit.";

    return true;
  },

  validateNegativeNumber: (v) => {
    return v >= 0 || "Can not be negative number.";
  },

  blockInvalidChar: (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault(),

  phone_number_validator: (number, country_code) => {
    if (validatePhoneNumberLength(number, country_code) === "TOO_SHORT")
      return "Mobile number is too short";
    if (validatePhoneNumberLength(number, country_code) === "TOO_LONG")
      return "Mobile number is too long";
    if (
      isPossiblePhoneNumber(number, country_code) &&
      isValidPhoneNumber(number, country_code)
    )
      return true;
    return "Mobile number is not valid";
  },
};
