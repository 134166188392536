export default {
  namespaced: true,
  state: {
    activeTab: {
      key: "pending",
      index: 0,
    },

    search: "",

    filters: {
      date: [],
      fe_ir: null,
      vendor: null,
      university: null,
      operations: null,
    },

    approveDialog: {
      show: false,
      payout_id: null,
    },

    rejectDialog: {
      show: false,
      payout_id: null,
    },
  },

  getters: {
    activeTab: (state) => state.activeTab,
    search: (state) => state.search,
    filters: (state) => state.filters,
    approveDialog: (state) => state.approveDialog,
    rejectDialog: (state) => state.rejectDialog,
  },

  mutations: {
    SET_ACTIVE_TAB(state, tab) {
      const { key, index } = tab;
      state.activeTab.key = key;
      state.activeTab.index = index;
    },

    SET_SEARCH(state, query) {
      state.search = query;
    },

    SET_FILTERS(state, filters) {
      const { date, fe_ir, vendor, university, operations } = filters;

      if ("date" in filters) {
        state.filters.date = date;
      }

      if ("fe_ir" in filters) {
        state.filters.fe_ir = fe_ir;
      }

      if ("vendor" in filters) {
        state.filters.vendor = vendor;
      }

      if ("university" in filters) {
        state.filters.university = university;
      }

      if ("operations" in filters) {
        state.filters.operations = operations;
      }
    },

    TOGGLE_APPROVE_DIALOG(state, obj) {
      const { show, payout_id } = obj;

      if (show) {
        state.approveDialog.payout_id = payout_id;
      } else {
        state.approveDialog.payout_id = null;
      }

      state.approveDialog.show = show;
    },

    TOGGLE_REJECT_DIALOG(state, obj) {
      const { show, payout_id } = obj;

      if (show) {
        state.rejectDialog.payout_id = payout_id;
      } else {
        state.rejectDialog.payout_id = null;
      }

      state.rejectDialog.show = show;
    },
  },
  actions: {
    setActiveTab({ commit }, obj) {
      commit("SET_ACTIVE_TAB", obj);
    },

    setSearch({ commit }, obj) {
      commit("SET_SEARCH", obj);
    },

    setFilters({ commit }, obj) {
      commit("SET_FILTERS", obj);
    },

    toggleApproveDialog({ commit }, obj) {
      commit("TOGGLE_APPROVE_DIALOG", obj);
    },

    toggleRejectDialog({ commit }, obj) {
      commit("TOGGLE_REJECT_DIALOG", obj);
    },
  },
};
