export default {
  namespaced: true,
  state: {
    activeTab: {
      key: "agent_ledger",
      index: 0,
    },

    search: "",

    dialog: {
      show: false,
      user_id: null,
    },

    addNoteDialog: {
      show: false,
      user_id: null,
    },
  },

  getters: {
    activeTab: (state) => state.activeTab,
    search: (state) => state.search,
    dialog: (state) => state.dialog,
    addNoteDialog: (state) => state.addNoteDialog,
  },

  mutations: {
    SET_ACTIVE_TAB(state, tab) {
      const { key, index } = tab;
      state.activeTab.key = key;
      state.activeTab.index = index;
    },

    SET_SEARCH(state, query) {
      state.search = query;
    },

    TOGGLE_DIALOG(state, obj) {
      const { show, user_id } = obj;

      if (show) {
        state.dialog.user_id = user_id;
      } else {
        state.dialog.user_id = null;
      }

      state.dialog.show = show;
    },

    TOGGLE_ADD_NOTE_DIALOG(state, obj) {
      const { show, user_id } = obj;

      if (show) {
        state.addNoteDialog.user_id = user_id;
      } else {
        state.addNoteDialog.user_id = null;
      }

      state.addNoteDialog.show = show;
    },
  },

  actions: {
    setActiveTab({ commit }, obj) {
      commit("SET_ACTIVE_TAB", obj);
    },

    setSearch({ commit }, obj) {
      commit("SET_SEARCH", obj);
    },

    toggleDialog({ commit }, obj) {
      commit("TOGGLE_DIALOG", obj);
    },

    toggleAddNoteDialog({ commit }, obj) {
      commit("TOGGLE_ADD_NOTE_DIALOG", obj);
    },
  },
};
