export default {
  // Master
  master: {
    // country
    country: {
      list: "/master/country/list/",
      create: "/master/country/create/",
    },

    // state
    state: {
      list: "/master/state/list/",
      create: "/master/state/create/",
    },

    // city
    city: {
      list: "/master/city/list/",
      create: "/master/city/create/",
    },

    source: {
      list: "/master/source/list/",
    },

    reason: {
      list: "/master/reason/list/",
    },

    course: {
      list: "/master/course/list/",
    },

    education: {
      list: "/master/education/list/",
    },

    service: {
      typeList: "/master/service-type/list/",

      list: "/master/service/list/",
      detail: "/master/service/detail/",
      create: "/master/service/create/",
      update: "/master/service/update/",
      delete: "/master/service/delete/",

      // vendor
      vendor: {
        create: "/master/service/vendor/create/",
        update: "/master/service/vendor/update/",
        delete: "/master/service/vendor/delete/",
      },
    },

    paymentMode: {
      list: "/master/payment-mode/list/",
    },

    shippingService: {
      list: "/master/shipping-service/list/",
    },

    refundClause: {
      list: "/master/refund-clause/list/",
    },

    document: {
      list: "/master/document/list/",
      detail: "/master/document/detail/",
      create: "/master/document/create/",
      update: "/master/document/update/",
      delete: "/master/document/delete/",
    },

    stripeCheckoutStatus: "/master/stripe-checkout-status/",
  },

  // Auth
  auth: {
    // login
    login: "/portal/login/",

    // reset password
    passwordGenerateLink: "/portal/reset-password/generate-link/",
    passwordCheckLink: "/portal/reset-password/check-link/",
    resetPasswordSubmit: "/portal/reset-password/submit/",

    // change password
    changePassword: "/portal/change-password/",

    // refresh token
    refreshToken: "/portal/refresh-token/",

    // set firebase messaging token
    setFcmToken: "/portal/set-fcm-token/",

    // profile
    profile: "/portal/profile/",
  },

  notification: {
    count: "/portal/notification/count/",
    list: "/portal/notification/list/",
    readAll: "/portal/notification/read-all/",
    clearAll: "/portal/notification/clear-all/",
  },

  report: {
    types: "/portal/report/types/",
    list: "/portal/report/list/",
    create: "/portal/report/create/",
    delete: "/portal/report/delete/",
  },

  dashboard: {
    topCounts: "/portal/dashboard/top-counts/",
    trends: "/portal/dashboard/trends/",
    revenue: "/portal/dashboard/revenue/",
    performance: "/portal/dashboard/performance/",
  },

  // team
  team: {
    list: "/team/list/",
    detail: "/team/detail/",
    create: "/team/create/",
    update: "/team/update/",
    delete: "/team/delete/",
    changeAvailability: "/team/change-availability/",
    payoutBalance: {
      get: "/team/payout-balance/",
      request: "/team/payout-balance/request/",
      requestApprove: "/team/payout-balance/request/approve/",
      requestReject: "/team/payout-balance/request/reject/",
    },
  },

  // agent
  agent: {
    list: "/agent/list/",
    detail: "/agent/detail/",
    create: "/agent/create/",
    update: "/agent/update/",
    delete: "/agent/delete/",
  },

  // university
  university: {
    list: "/university/list/",
    detail: "/university/detail/",
    create: "/university/create/",
    update: "/university/update/",
    delete: "/university/delete/",
    serviceCost: {
      list: "/university/service-cost/list/",
      update: "/university/service-cost/update/",
    },
    case: {
      create: "/portal/university/case/create/",
      accept: "/portal/university/case/accept/",
      reject: "/portal/university/case/reject/",
      initiateStripeCheckout:
        "/portal/university/case/initiate-stripe-checkout/",
    },
  },

  // eca
  eca: {
    list: "/eca/list/",
    detail: "/eca/detail/",
    create: "/eca/create/",
    update: "/eca/update/",
    delete: "/eca/delete/",
  },

  // client
  client: {
    count: "/client/count/",
    list: "/client/list/",
    detail: "/client/detail/",
    create: "/client/create/",
    update: "/client/update/",
    delete: "/client/delete/",
    assignSales: "/client/assign-sales/",
    unAssignSales: "/client/unassign-sales/",
    sendQueryMail: "/client/send-query-mail/",
    moveToFollowUp: "/client/move-to-follow-up/",
    markAsIrrelevant: "/client/mark-as-irrelevant/",
    membership: {
      planList: "/client/membership/plan-list/",
      upgradePlan: "/client/membership/upgrade-membership-plan/",
    },
  },

  // case
  case: {
    count: "/case/count/",
    list: "/case/list/",
    create: "/case/create/",
    update: "/case/update/",
    delete: "/case/delete/",
    detail: "/case/detail/",
    defaultPricing: "/case/default-pricing/",

    // actions
    sendQuotation: "/case/send-quotation/",

    markAsIrrelevant: "/case/mark-as-irrelevant/",
    moveToFollowUp: "/case/move-to-follow-up/",
    moveToOnHold: "/case/move-to-on-hold/",

    downloadInvoice: "/case/invoice/download/",
    sendInvoice: "/case/invoice/send-email/",
    sendWelcomeEmail: "/case/send-welcome-email/",

    trackingInfo: "/case/tracking-info/",
    close: "/case/close/",
    changeEcaStatus: "/case/change-eca-status",

    accountClearance: {
      initiate: "/case/account-clearance/initiate/",
      approve: "/case/account-clearance/approve/",
      reject: "/case/account-clearance/reject/",
    },

    // assignment actions
    assignSales: "/case/assign-sales/",
    unAssignSales: "/case/unassign-sales/",
    assignOperations: "/case/assign-operations/",
    unAssignOperations: "/case/unassign-operations/",
    assignFeIr: "/case/assign-fe-ir/",
    unAssignFeIr: "/case/unassign-fe-ir/",
    assignVendor: "/case/assign-vendor/",
    unAssignVendor: "/case/unassign-vendor/",
    assignUniversity: "/case/assign-university/",
    unAssignUniversity: "/case/unassign-university/",

    // documents
    document: {
      list: "/case/document/list/",
      create: "/case/document/create/",
      update: "/case/document/update/",
      delete: "/case/document/delete/",
      submit: "/case/document/submit/",
      download: "/case/document/download/",
    },

    // payment
    payment: {
      count: "/case/payment/count/",
      list: "/case/payment/list/",
      detail: "/case/payment/detail/",
      create: "/case/payment/create/",
      update: "/case/payment/update/",
      delete: "/case/payment/delete/",
      approve: "/case/payment/approve/",
      reject: "/case/payment/reject/",
      downloadReceipt: "/case/payment/download-receipt/",
    },

    // payout
    payout: {
      count: "/case/payout/count/",
      list: "/case/payout/list/",
      create: "/case/payout/create/",
      approve: "/case/payout/approve/",
      reject: "/case/payout/reject/",
    },

    // refund
    refund: {
      count: "/case/refund/count/",
      list: "/case/refund/list/",
      detail: "/case/refund/detail/",
      create: "/case/refund/create/",
      update: "/case/refund/update/",
      delete: "/case/refund/delete/",
      approve: "/case/refund/approve/",
      reject: "/case/refund/reject/",
    },

    // tat change request
    tatChangeRequest: {
      count: "/case/tat-change-request/count/",
      list: "/case/tat-change-request/list/",
      create: "/case/tat-change-request/create/",
      approve: "/case/tat-change-request/approve/",
      reject: "/case/tat-change-request/reject/",
    },

    // message
    message: {
      list: "/case/message/list/",
      create: "/case/message/create/",
    },
  },

  ledger: {
    list: "/ledger/list/",
    create: "/ledger/create/",
  },
};
