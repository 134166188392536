<template>
  <v-dialog :value="value" persistent width="450" content-class="rounded-xl">
    <v-card :outlined="$vuetify.theme.dark" rounded="xl">
      <v-card-text>
        <div class="d-flex justify-center py-4">
          <vue-lottie
            :animationData="require('@/assets/animation/no-internet.json')"
            style="height: 200px; width: 200px"
            :loop="true"
          />
        </div>
        <div class="mt-4">
          Try:
          <ul>
            <li>Checking the network cables, modem and router</li>
            <li>Reconnecting to Wi-Fi</li>
          </ul>
          <p class="mt-2 text-caption">ERR_INTERNET_DISCONNECTED</p>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: ["value"],
};
</script>

