export default {
  namespaced: true,
  state: {
    dialog: {
      show: false,
      type: null,
      country: null,
    },
  },
  getters: {
    dialog: (state) => state.dialog,
  },
  mutations: {
    TOGGLE_DIALOG(state, obj) {
      state.dialog.show = obj.show;
      state.dialog.type = obj.type;
      if (obj.country) state.dialog.country = obj.country;
    },
  },
  actions: {
    toggleDialog({ commit }, obj) {
      commit("TOGGLE_DIALOG", obj);
    },
  },
};
