import { parsePhoneNumber } from "libphonenumber-js";
import moment from "moment";
import { keys } from "@/helper";

const imageExtensions = [
  "gif",
  "jpg",
  "jpeg",
  "jfif",
  "pjpeg",
  "pjp",
  "png",
  "svg",
  "webp",
  "bmp",
  "ico",
];

const pdfExtensions = ["pdf"];

export default {
  debug(text) {
    console.log(text);
  },

  formatDate(date, format = "D MMM YYYY") {
    if (date) {
      return moment(date).format(format);
    }
    return "";
  },

  getPhoneNumberInfo(number, country_code) {
    const phoneNumber = parsePhoneNumber(number, country_code);
    if (phoneNumber) {
      return {
        phone_number: phoneNumber.nationalNumber,
        phone_code: phoneNumber.countryCallingCode,
        country_code: phoneNumber.country,
      };
    } else return null;
  },

  getCurrentDate(currentDate) {
    return moment(currentDate).format("YYYY-MM-DD");
  },

  formatAmount(value) {
    const formatter = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
    });
    return formatter.format(value);
  },

  sleep(sec) {
    return new Promise((r) => setTimeout(r, sec * 1000));
  },

  getFilenameFromUrl(url) {
    return url.split("/").pop().split("?")[0].split("#")[0];
  },

  getFileTypeFromUrl(url) {
    // eslint-disable-next-line no-useless-escape
    const ext = String(url).split(".").pop().split(/\#|\?/)[0];

    if (imageExtensions.includes(ext)) return "image";
    if (pdfExtensions.includes(ext)) return "pdf";
    return null;
  },

  getSourceIcon(source) {
    switch (source) {
      case keys.WEB:
        return "mdi-web";
      case keys.ANDROID:
        return "mdi-android";
      case keys.IOS:
        return "mdi-apple";
      case keys.WHATSAPP:
        return "mdi-whatsapp";
      case keys.CALL:
        return "mdi-phone";
      default:
        return "";
    }
  },

  getSourceColor(source) {
    switch (source) {
      case keys.WEB:
        return "primary";
      case keys.ANDROID:
        return "green";
      case keys.IOS:
        return "black";
      case keys.WHATSAPP:
        return "green";
      case keys.CALL:
        return "black";
      default:
        return "";
    }
  },
};
