export function getFirebaseConfig(environment) {
  if (environment === "production") {
    return {
      apiKey: "AIzaSyB6LWW82wY0tpRf5efIhJYlzX-ri7oSxTA",
      authDomain: "world-document-services.firebaseapp.com",
      projectId: "world-document-services",
      storageBucket: "world-document-services.appspot.com",
      messagingSenderId: "833133224668",
      appId: "1:833133224668:web:30fd1b6255a40c58688a19",
      measurementId: "G-C5M8S5XSDM",
    };
  } else {
    return {
      apiKey: "AIzaSyCPiT8QolskQtklTEACMUkJF3cMLRWeW7I",
      authDomain: "world-document-services-dev.firebaseapp.com",
      projectId: "world-document-services-dev",
      storageBucket: "world-document-services-dev.appspot.com",
      messagingSenderId: "429021458476",
      appId: "1:429021458476:web:5fc8c093c0cceb43c26189",
    };
  }
}
