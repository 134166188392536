export default {
    namespaced: true,
    state: {
        search: "",
        date: "",
        reason: "",
    },
    getters: {
        getSearch: (state) => state.search,
        getDate: (state) => state.date,
        getReason: (state) => state.reason,
    },
    mutations: {
        SEARCH(state, obj) {
            state.search = obj;
        },
        DATE(state, obj) {
            state.date = obj;
        },
        REASON(state, obj) {
            state.reason = obj;
        },
    },
    actions: {
        setSearch({ commit }, obj) {
            commit("SEARCH", obj);
        },
        setDate({ commit }, obj) {
            commit("DATE", obj);
        },
        setReason({ commit }, obj) {
            commit("REASON", obj);
        },
    },
};
