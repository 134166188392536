export default {
  namespaced: true,
  state: {
    filters: {
      service_type: "",
      executive: null,
      date_filter_type: "today",
      start_date: "",
      end_date: "",
    },
  },

  getters: {
    filters: (state) => state.filters,
  },

  mutations: {
    UPDATE_FILTERS(state, obj) {
      state.filters = obj;
    },
  },

  actions: {
    updateFilters({ commit }, obj) {
      commit("UPDATE_FILTERS", obj);
    },
  },
};
