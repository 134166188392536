export default {
  namespaced: true,
  state: {
    passwordChangeDialog: {
      show: false,
    },

    availabilityDialog: {
      show: false,
    },

    sendQuotationDialog: {
      show: false,
      caseId: null,
      clientId: null,
    },

    caseCommonDialog: {
      show: false,
      caseId: null,
      type: null,
    },

    tatHistoryDialog: {
      show: false,
      caseId: null,
    },

    addDocumentDialog: {
      show: false,
      caseId: null,
    },

    uploadDocumentDialog: {
      show: false,
      caseId: null,
    },

    addTrackingInfoDialog: {
      show: false,
      item: null,
    },

    documentViewer: {
      show: false,
      items: [],
      title: "",
      subtitle: "",
    },

    confirmationDialog: {
      show: false,
      action: "",
      confirmBtnLoading: false,
    },

    serviceTypeDialog: {
      show: false,
    },

    stripeCheckoutDialog: {
      show: false,
      items: [],
    },

    upgradePlanDialog: {
      show: false,
      item: null,
    },
  },

  getters: {
    passwordChangeDialog: (state) => state.passwordChangeDialog,
    availabilityDialog: (state) => state.availabilityDialog,
    sendQuotationDialog: (state) => state.sendQuotationDialog,
    caseCommonDialog: (state) => state.caseCommonDialog,
    documentViewer: (state) => state.documentViewer,
    confirmationDialog: (state) => state.confirmationDialog,
    serviceTypeDialog: (state) => state.serviceTypeDialog,
    tatHistoryDialog: (state) => state.tatHistoryDialog,
    addDocumentDialog: (state) => state.addDocumentDialog,
    uploadDocumentDialog: (state) => state.uploadDocumentDialog,
    addTrackingInfoDialog: (state) => state.addTrackingInfoDialog,
    stripeCheckoutDialog: (state) => state.stripeCheckoutDialog,
    upgradePlanDialog: (state) => state.upgradePlanDialog,
  },

  mutations: {
    TOGGLE_PASSWORD_CHANGE_Dialog(state, obj) {
      const { show } = obj;
      state.passwordChangeDialog.show = show;
    },

    TOGGLE_AVAILABILITY_DIALOG(state, obj) {
      const { show } = obj;
      state.availabilityDialog.show = show;
    },

    TOGGLE_SEND_QUOTATION_DIALOG(state, obj) {
      console.log(obj);
      const { show, caseId, clientId } = obj;
      if (show) {
        state.sendQuotationDialog.caseId = caseId;
        state.sendQuotationDialog.clientId = clientId;
      } else {
        state.sendQuotationDialog.caseId = null;
        state.sendQuotationDialog.clientId = null;
      }
      state.sendQuotationDialog.show = show;
    },

    TOGGLE_CASE_COMMON_DIALOG(state, obj) {
      const { show, caseId, type } = obj;
      if (show) {
        state.caseCommonDialog.caseId = caseId;
        state.caseCommonDialog.type = type;
      } else {
        state.caseCommonDialog.caseId = null;
        state.caseCommonDialog.type = null;
      }
      state.caseCommonDialog.show = show;
    },

    TOGGLE_DOCUMENT_VIEWER(state, obj) {
      const { show, items, title, subtitle } = obj;
      if (show) {
        state.documentViewer.items = items;
        state.documentViewer.title = title;
        state.documentViewer.subtitle = subtitle;
      } else {
        state.documentViewer.items = [];
        state.documentViewer.title = "";
        state.documentViewer.subtitle = "";
      }
      state.documentViewer.show = show;
    },

    TOGGLE_CONFIRMATION_DIALOG(state, obj) {
      const { show, action } = obj;
      if (show) {
        state.confirmationDialog.action = action;
      } else {
        state.confirmationDialog.confirmBtnLoading = false;
      }
      state.confirmationDialog.show = show;
    },

    SET_CONFIRMATION_DIALOG_BTN_LOADING(state, obj) {
      const { loading } = obj;
      state.confirmationDialog.confirmBtnLoading = loading;
    },

    TOGGLE_SERVICE_TYPE_DIALOG(state, obj) {
      const { show } = obj;
      state.serviceTypeDialog.show = show;
    },

    TOGGLE_TAT_HISTORY_DIALOG(state, obj) {
      const { show, caseId } = obj;

      if (show) {
        state.tatHistoryDialog.caseId = caseId;
      } else {
        state.tatHistoryDialog.caseId = null;
      }

      state.tatHistoryDialog.show = show;
    },

    TOGGLE_ADD_DOCUMENT_DIALOG(state, obj) {
      const { show, caseId } = obj;

      if (show) {
        state.addDocumentDialog.caseId = caseId;
      } else {
        state.addDocumentDialog.caseId = null;
      }

      state.addDocumentDialog.show = show;
    },

    TOGGLE_UPLOAD_DOCUMENT_DIALOG(state, obj) {
      const { show, caseId } = obj;

      if (show) {
        state.uploadDocumentDialog.caseId = caseId;
      } else {
        state.uploadDocumentDialog.caseId = null;
      }

      state.uploadDocumentDialog.show = show;
    },

    TOGGLE_ADD_TRACKING_INFO_DIALOG(state, obj) {
      const { show, item } = obj;

      if (show) {
        state.addTrackingInfoDialog.item = item;
      } else {
        state.addTrackingInfoDialog.item = null;
      }

      state.addTrackingInfoDialog.show = show;
    },

    TOGGLE_STRIPE_CHECKOUT_DIALOG(state, obj) {
      const { show, items } = obj;
      if (show) {
        state.stripeCheckoutDialog.items = items;
      } else {
        state.stripeCheckoutDialog.items = [];
      }
      state.stripeCheckoutDialog.show = show;
    },

    TOGGLE_UPGRADE_PLAN_DIALOG(state, obj) {
      const { show, item } = obj;
      if (show) {
        state.upgradePlanDialog.item = item;
      } else {
        state.upgradePlanDialog.item = null;
      }
      state.upgradePlanDialog.show = show;
    },
  },

  actions: {
    togglePasswordChangeDialog({ commit }, obj) {
      commit("TOGGLE_PASSWORD_CHANGE_Dialog", obj);
    },

    toggleAvailabilityDialog({ commit }, obj) {
      commit("TOGGLE_AVAILABILITY_DIALOG", obj);
    },

    toggleSendQuotationDialog({ commit }, obj) {
      commit("TOGGLE_SEND_QUOTATION_DIALOG", obj);
    },

    toggleCaseCommonDialog({ commit }, obj) {
      commit("TOGGLE_CASE_COMMON_DIALOG", obj);
    },

    toggleDocumentViewer({ commit }, obj) {
      commit("TOGGLE_DOCUMENT_VIEWER", obj);
    },

    toggleConfirmationDialog({ commit }, obj) {
      commit("TOGGLE_CONFIRMATION_DIALOG", obj);
    },

    setConfirmationDialogBtnLoading({ commit }, obj) {
      commit("SET_CONFIRMATION_DIALOG_BTN_LOADING", obj);
    },

    toggleServiceTypeDialog({ commit }, obj) {
      commit("TOGGLE_SERVICE_TYPE_DIALOG", obj);
    },

    toggleTatHistoryDialog({ commit }, obj) {
      commit("TOGGLE_TAT_HISTORY_DIALOG", obj);
    },

    toggleAddDocumentDialog({ commit }, obj) {
      commit("TOGGLE_ADD_DOCUMENT_DIALOG", obj);
    },

    toggleUploadDocumentDialog({ commit }, obj) {
      commit("TOGGLE_UPLOAD_DOCUMENT_DIALOG", obj);
    },

    toggleAddTrackingInfoDialog({ commit }, obj) {
      commit("TOGGLE_ADD_TRACKING_INFO_DIALOG", obj);
    },

    toggleStripeCheckoutDialog({ commit }, obj) {
      commit("TOGGLE_STRIPE_CHECKOUT_DIALOG", obj);
    },

    toggleUpgradePlanDialog({ commit }, obj) {
      commit("TOGGLE_UPGRADE_PLAN_DIALOG", obj);
    },
  },
};
