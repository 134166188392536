export default {
  namespaced: true,
  state: {
    activeTab: {
      key: "",
      index: 0,
    },

    search: "",

    filters: {
      executive: null,
      university: null,
      eca: null,
      service_type: null,
      source: null,
      date_filter_type: "",
      start_date: "",
      end_date: "",
    },

    payoutForm: {
      item: null,
      show: false,
      type: "",
    },

    executiveModal: {
      id: "",
      show: false,
      type: "",
      university_id: "",
    },

    requestTatModal: {
      item: {},
      type: "",
      show: false,
    },

    ecaStatusHistoryDialog: {
      show: false,
      items: [],
    },
  },
  getters: {
    activeTab: (state) => state.activeTab,
    search: (state) => state.search,
    filters: (state) => state.filters,
    getExecutiveModal: (state) => state.executiveModal,
    getPayoutForm: (state) => state.payoutForm,
    getRequestTatModal: (state) => state.requestTatModal,
    ecaStatusHistoryDialog: (state) => state.ecaStatusHistoryDialog,
  },
  mutations: {
    SET_ACTIVE_TAB(state, tab) {
      const { key, index } = tab;
      state.activeTab.key = key;
      state.activeTab.index = index;
    },

    SET_SEARCH(state, query) {
      state.search = query;
    },

    UPDATE_FILTERS(state, obj) {
      state.filters = obj;
    },

    PAYOUT_FORM(state, obj) {
      if (obj.show) {
        state.payoutForm.item = obj.item ? obj.item : {};
        state.payoutForm.type = obj.type ? obj.type : "";
      }
      state.payoutForm.show = obj.show;
    },
    EXECUTIVE_MODAL(state, obj) {
      state.executiveModal.show = obj.show;
      state.executiveModal.id = obj.id;
      state.executiveModal.type = obj.type;
      if (obj.university_id)
        state.executiveModal.university_id = obj.university_id;
    },

    REQUEST_TAT_MODAL(state, obj) {
      if (obj.show) {
        state.requestTatModal.item = obj.item ? obj.item : {};
        state.requestTatModal.type = obj.type ? obj.type : "";
      }
      state.requestTatModal.show = obj.show;
    },

    TOGGLE_ECA_STATUS_HISTORY_DIALOG(state, obj) {
      state.ecaStatusHistoryDialog = obj;
    },
  },
  actions: {
    setActiveTab({ commit }, obj) {
      commit("SET_ACTIVE_TAB", obj);
    },

    setSearch({ commit }, obj) {
      commit("SET_SEARCH", obj);
    },

    updateFilters({ commit }, obj) {
      commit("UPDATE_FILTERS", obj);
    },

    payoutForm({ commit }, obj) {
      commit("PAYOUT_FORM", obj);
    },

    executiveModal({ commit }, obj) {
      commit("EXECUTIVE_MODAL", obj);
    },

    requestTatModal({ commit }, obj) {
      commit("REQUEST_TAT_MODAL", obj);
    },

    toggleEcaStatusHistoryDialog({ commit }, obj) {
      commit("TOGGLE_ECA_STATUS_HISTORY_DIALOG", obj);
    },
  },
};
