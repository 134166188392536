export default {
  namespaced: true,
  state: {
    addUpdateDialog: {
      show: false,
      caseId: null,
      type: null,
      id: null,
    },

    requestDialog: {
      show: false,
      caseId: null,
      type: null,
      id: null,
    },
  },

  getters: {
    addUpdateDialog: (state) => state.addUpdateDialog,
    requestDialog: (state) => state.requestDialog,
  },

  mutations: {
    TOGGLE_ADD_UPDATE_DIALOG(state, obj) {
      const { show, caseId, type, id } = obj;

      if (show) {
        if ("caseId" in obj) {
          state.addUpdateDialog.caseId = caseId;
        }
        if ("type" in obj) {
          state.addUpdateDialog.type = type;
        }
        if ("id" in obj) {
          state.addUpdateDialog.id = id;
        }
      } else {
        state.addUpdateDialog.caseId = null;
        state.addUpdateDialog.type = null;
        state.addUpdateDialog.id = null;
      }
      state.addUpdateDialog.show = show;
    },

    TOGGLE_REQUEST_DIALOG(state, obj) {
      const { show, caseId, type, id } = obj;

      if (show) {
        if ("caseId" in obj) {
          state.requestDialog.caseId = caseId;
        }
        if ("type" in obj) {
          state.requestDialog.type = type;
        }
        if ("id" in obj) {
          state.requestDialog.id = id;
        }
      } else {
        state.requestDialog.caseId = null;
        state.requestDialog.type = null;
        state.requestDialog.id = null;
      }
      state.requestDialog.show = show;
    },
  },

  actions: {
    toggleAddUpdateDialog({ commit }, obj) {
      commit("TOGGLE_ADD_UPDATE_DIALOG", obj);
    },
    toggleRequestDialog({ commit }, obj) {
      commit("TOGGLE_REQUEST_DIALOG", obj);
    },
  },
};
