export default {
  namespaced: true,
  state: {
    settingsDrawer: {
      show: false,
    },
  },

  getters: {
    settingsDrawer: (state) => state.settingsDrawer,
  },

  mutations: {
    TOGGLE_SETTINGS_DRAWER(state, obj) {
      const { show } = obj;
      state.settingsDrawer.show = show;
    },
  },

  actions: {
    toggleSettingsDrawer({ commit }, obj) {
      commit("TOGGLE_SETTINGS_DRAWER", obj);
    },
  },
};
