export default {
  // platform
  PLATFORM: "platform",
  SOURCE: "source",
  WEB: "web",
  ANDROID: "android",
  IOS: "ios",
  WHATSAPP: "whatsapp",
  CALL: "call",

  // request methods
  GET: "get",
  POST: "post",
  PATCH: "patch",
  PUT: "put",
  DELETE: "delete",

  ADD: "add",
  EDIT: "edit",
  UPDATE: "update",

  //   user type
  ADMIN: "admin",
  SUPER_ADMIN: "super_admin",
  SALES_EXECUTIVE: "sales_executive",
  OPERATIONS_EXECUTIVE: "operations_executive",
  SALES_HEAD: "sales_head",
  OPERATIONS_HEAD: "operations_head",
  ACCOUNTS: "accounts",
  UNIVERSITY: "university",
  ECA_BODY: "eca_body",

  FE_IR: "fe_ir",
  VENDOR: "vendor",

  CLIENT: "client",
  TEAM: "team",

  //
  ASSIGNED_TO_ME: "assigned_to_me",
  ASSIGNED_TO_SALES_EXECUTIVE: "assigned_to_sales_executive",

  // Payment
  ONLINE: "online",
  OFFLINE: "offline",

  AUTOMATIC: "automatic",
  MANUAL: "manual",

  AVAILABLE: "available",
  UNAVAILABLE: "unavailable",

  // service type
  EDUCATIONAL_DOCUMENTATION: "educational_documentation",
  ELECTRONIC_TRANSCRIPTS: "electronic_transcripts",
  APOSTILLE_TRANSLATIONS: "apostille_translations",
  CERTIFICATIONS: "certifications",

  SERVICE_TYPES: [
    { key: "educational_documentation", label: "Educational Documentation" },
    { key: "electronic_transcripts", label: "Electronic Transcripts" },
    { key: "apostille_translations", label: "Apostille Translations" },
    { key: "certifications", label: "Certifications" },
  ],

  SOURCE_TYPES: [
    { key: "web", label: "Web", icon: "mdi-web" },
    { key: "android", label: "Android", icon: "mdi-android" },
    { key: "ios", label: "iOS", icon: "mdi-apple" },
    { key: "whatsapp", label: "WhatsApp", icon: "mdi-whatsapp" },
    { key: "call", label: "Call", icon: "mdi-phone" },
  ],

  E_SHIPMENT: "e_shipment",
  PHYSICAL_SHIPMENT: "physical_shipment",
  BOTH: "both",

  QUOTATION_TYPES: [
    { key: "bifurcated", label: "Bifurcated" },
    { key: "consolidated", label: "Consolidated" },
  ],

  DELIVERY_TYPES: [
    { key: "e_shipment", label: "E-shipment" },
    { key: "physical_shipment", label: "Physical Shipment" },
    { key: "both", label: "Both (E-shipment &  Physical Shipment)" },
  ],

  EDUCATIONAL: "educational",
  PERSONAL: "personal",
  COMMERCIAL: "commercial",

  DOCUMENT_TYPES: [
    { key: "educational", label: "Educational" },
    { key: "personal", label: "Personal" },
    { key: "commercial", label: "Commercial" },
  ],

  // fields
  STATE: "state",
  CITY: "city",
  EDUCATION: "education",
  COURSE: "course",
  DOCUMENT_TYPE: "document_type",
  NO_OF_SETS: "no_of_sets",
  NO_OF_DOCUMENTS: "no_of_documents",
  SEND_TO_ECA: "send_to_eca",
  ECA: "eca",
  ECA_REFERENCE_NUMBER: "eca_reference_number",
  DELIVERY_TYPE: "delivery_type",

  UNIVERSITY_COST: "university_cost",
  UNIVERSITY_COST_PER_DOCUMENT: "university_cost_per_document",
  UNIVERSITY_ADDITIONAL_COST: "university_additional_cost",
  PROCESSING_COST: "processing_cost",
  PROCESSING_COST_PER_COPY: "processing_cost_per_copy",
  PROCESSING_COST_PER_DOCUMENT: "processing_cost_per_document",
  PROCESSING_ADDITIONAL_COST: "processing_additional_cost",
  SHIPPING_COST: "shipping_cost",
  GST_PERCENT: "gst_percent",
  DISCOUNT_PERCENT: "discount_percent",
  TOTAL_UNIVERSITY_COST: "total_university_cost",

  CLIENT_TAT_DAYS: "client_tat_days",
  INTERNAL_TAT_DAYS: "internal_tat_days",

  FOLLOW_UP: "follow_up",
  ON_HOLD: "on_hold",
  IRRELEVANT: "irrelevant",
  CANCELLED: "cancelled",

  // actions
  VIEW_CASE: "view_case",
  EDIT_CASE: "edit_case",
  DELETE_CASE: "delete_case",

  REQUEST_TAT_CHANGE: "request_tat_change",
  APPROVE_TAT_CHANGE: "approve_tat_change",
  REJECT_TAT_CHANGE: "reject_tat_change",

  VIEW_DOCUMENTS: "view_documents",
  DOWNLOAD_DOCUMENTS: "download_documents",

  INVOICE_DOWNLOAD: "invoice_download",
  INVOICE_SEND_EMAIL: "invoice_send_email",
  SEND_WELCOME_EMAIL: "send_welcome_email",

  DOCUMENT_ADD: "document_add",
  DOCUMENT_DELETE: "document_DELETE",
  DOCUMENT_APPROVE: "document_APPROVE",
  DOCUMENT_REJECT: "document_REJECT",

  PAYMENT_ADD: "payment_add",
  ADDITIONAL_PAYMENT_ADD: "additional_payment_add",
  ADDITIONAL_PAYMENT_REQUEST: "additional_payment_request",
  PAYMENT_APPROVE: "payment_approve",
  PAYMENT_REJECT: "payment_reject",
  PAYMENT_DELETE: "payment_delete",
  PAYMENT_EDIT: "payment_edit",
  PAYMENT_RECEIPT_DOWNLOAD: "payment_receipt_download",

  PAYOUT_ADD: "payout_add",
  PAYOUT_APPROVE: "payout_approve",
  PAYOUT_REJECT: "payout_reject",

  ASSIGN_SALES: "assign_sales",
  UNASSIGN_SALES: "unassign_sales",
  CHANGE_SALES: "change_sales",

  ASSIGN_OPERATIONS: "assign_operations",
  UNASSIGN_OPERATIONS: "unassign_operations",
  CHANGE_OPERATIONS: "change_operations",

  UNASSIGN_ME: "unassign_me",

  UNASSIGN_FE_IR: "unassign_fe_ir",
  CHANGE_FE_IR: "change_fe_ir",

  ASSIGN_UNIVERSITY: "assign_university",
  UNASSIGN_UNIVERSITY: "unassign_university",

  PENDING: "pending",
  UPLOADED: "uploaded",
  VERIFIED: "verified",
  REJECTED: "rejected",

  // message
  CLIENT_TEAM: "client_team",
  TEAM_TEAM: "team_team",
  FE_IR_TEAM: "fe_ir_team",
};
