import Vue from "vue";
import VueRouter from "vue-router";
import authToken from "@/common/authToken";
import routes from "@/router/routes"

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,

  //For Default Top Scroll
  scrollBehavior: (to, from, savedPosition) =>
    new Promise(() => {
      const position = savedPosition || {};
      if (!savedPosition) {
        if (to.hash) {
          position.selector = to.hash;
        }
        if (to.matched.some((m) => m.meta.scrollToTop)) {
          position.x = 0;
          position.y = 0;
        }
      }
      window.scrollTo(position.x, position.y);
    }),
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title
    ? `${to.meta.title} — World Document Services`
    : "World Document Services";
    
  const { accessToken, refreshToken } = authToken.getToken();
  if (
    accessToken &&
    refreshToken &&
    (to.name === "login" ||
      to.name === "forgetPassword" ||
      to.name === "resetPassword")
  )
    next({ name: "dashboard" });
  else if (!to.meta.isPublic) {
    if (!accessToken && !refreshToken) {
      next({ name: "login" });
    } else next();
  } else next();
});

export default router;
