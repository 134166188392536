<template>
  <v-dialog
    transition="scroll-y-transition"
    scrollable
    v-model="show"
    max-width="480px"
    content-class="rounded-xl"
  >
    <v-card :outlined="$vuetify.theme.dark" rounded="xl">
      <v-card-title class="d-flex align-start justify-space-between">
        <div class="text-h6">Select Service Type</div>
        <v-icon @click="modalClosed" size="26">mdi-close</v-icon>
      </v-card-title>
      <v-divider />
      <v-card-text class="pa-0">
        <v-list>
          <v-list-item-group
            v-model="selected"
            active-class="primary--text"
            mandatory
          >
            <template v-for="(item, index) in service_types">
              <v-list-item :key="item.key" @click="onSelected(item)">
                <template v-slot:default>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.value"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
              <v-divider
                v-if="index < service_types.length - 1"
                :key="index"
              ></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      service_types: "getServiceTypes",
      serviceTypeDialog: "dialogs/serviceTypeDialog",
    }),
    show: {
      get() {
        return this.serviceTypeDialog.show;
      },
      set(value) {
        this.toggleServiceTypeDialog({ show: value });
      },
    },
    selected: {
      get() {
        return this.serviceTypeDialog.selected;
      },
      set(value) {
        this.$store.state.topNav.serviceTypeSwitch.selected = value;
      },
    },
  },

  watch: {
    show(value) {
      if (value) {
        this.openModal();
      } else this.modalClosed();
    },
  },
  methods: {
    ...mapActions({
      toggleServiceTypeDialog: "dialogs/toggleServiceTypeDialog",
    }),

    openModal() {},
    modalClosed() {
      this.toggleServiceTypeDialog({ show: false });
    },

    onSelected() {
      this.modalClosed();
    },
  },
};
</script>