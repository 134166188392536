<template>
  <div class="main-div d-flex justify-center align-center">
    <div class="background">
      <div class="blur"></div>
    </div>
    <v-card
      width="500"
      height="100%"
      class="pa-4"
      :outlined="$vuetify.theme.dark"
      rounded="xl"
    >
      <v-card-text>
        <router-view />
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.main-div {
  min-height: 100%;
}
.background {
  background-image: url("https://images.unsplash.com/photo-1544396821-4dd40b938ad3");
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.blur {
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(6px);
  height: 100%;
  width: 100%;
}
</style>
